<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="86"
    viewBox="0 0 86 86"
  >
    <g id="Icon" transform="translate(0)">
      <rect
        id="Area"
        width="86"
        height="86"
        transform="translate(0 0)"
        fill="#fcfcfc"
        opacity="0"
      />
      <g id="Icon-2" data-name="Icon" transform="translate(14.352 9.641)">
        <path
          id="Path"
          d="M31.986,2.242,52.269,22.521a28.666,28.666,0,1,1-40.528,0Z"
          transform="translate(-3.338 -2.242)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "iconHigiene",
};
</script>
<style scoped>
#Icon {
  margin-top: 5px;
}
</style>
