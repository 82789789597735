import iconComunEye from "@/components/iconos/comunes/iconComunEye.vue";
import iconComunEyeOff from "@/components/iconos/comunes/iconComunEyeOff.vue";
import iconComunUsuario from "@/components/iconos/comunes/iconComunUsuario.vue";
import iconComunPassword from "@/components/iconos/comunes/iconComunPassword.vue";
import iconGestion from "@/components/iconos/iconGestion.vue";
import iconGestionWhite from "@/components/iconos/iconGestion-white.vue";
import iconCorderos from "@/components/iconos/iconCorderos.vue";
import iconCorderosWhite from "@/components/iconos/iconCorderos-white.vue";
import iconLegales from "@/components/iconos/iconLegales.vue";
import iconLegalesWhite from "@/components/iconos/iconLegales-white.vue";
import iconGTE2 from "@/components/iconos/iconGTE2.vue";
import iconGTE2White from "@/components/iconos/iconGTE2-white.vue";
import iconHigiene from "@/components/iconos/iconHigiene.vue";
import iconHigieneWhite from "@/components/iconos/iconHigiene-white.vue";
import iconTrabajo from "@/components/iconos/iconTrabajo.vue";
import iconTrabajoWhite from "@/components/iconos/iconTrabajo-white.vue";

export default {
	values: {
		iconComunEye: {
			component: iconComunEye,
		},
        iconComunEyeOff: {
			component: iconComunEyeOff,
		},
        iconComunUsuario: {
			component: iconComunUsuario,
		},
        iconComunPassword: {
			component: iconComunPassword,
		},
		iconGestion: {
			component: iconGestion,
		},
		iconGestionWhite: {
			component: iconGestionWhite,
		},
		iconCorderos: {
			component: iconCorderos,
		},
		iconCorderosWhite: {
			component: iconCorderosWhite,
		},
		iconLegales: {
			component: iconLegales,
		},
		iconLegalesWhite: {
			component: iconLegalesWhite,
		},
		iconGTE2: {
			component: iconGTE2,
		},
		iconGTE2White: {
			component: iconGTE2White,
		},
		iconHigiene: {
			component: iconHigiene,
		},
		iconHigieneWhite: {
			component: iconHigieneWhite,
		},
		iconTrabajo: {
			component: iconTrabajo,
		},
		iconTrabajoWhite: {
			component: iconTrabajoWhite,
		}
    },
};
