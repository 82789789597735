<template>
  <v-sheet
    style="overflow-y: hidden; overflow-x: hidden"
    :height="altoVista"
    v-resize="onResize"
    class="mt-1"
  >
    <!--filtro-->
    <v-row justify="center" align="center">
      <v-col cols="12">
        <p class="text-center azul1--text mb-4">{{ filtroTitle }}</p>
        <p class="text-center azul1--text mb-4">{{ filtroTitle2 }}</p>
        <v-sheet rounded elevation="4" class="mb-4" id="filtro">
          <slot name="filtro"></slot>
        </v-sheet>
      </v-col>
    </v-row>

    <!--botones-->
    <div class="text-center ml-n5">
      <slot name="botones"> </slot>
    </div>

    <!--tabla-->
    <slot name="tabla"></slot>
    <!-- dialogs -->
    <slot name="dialog" id="dialog"></slot>
  </v-sheet>
</template>

<script>
export default {
  name: "VistaModeloLienzos",
  props: {
    filtroTitle: {
      type: String,
      default: "Título filtro...",
    },
    filtroTitle2: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    altoVista: window.innerHeight,
  }),
  methods: {
    onResize() {
      this.altoVista = window.innerHeight - this.$store.state.offsetVista;
    },
  },
};
</script>

<style>
#filtro {
  background-color: #f4f6f7;
}
#dialog {
  background-color: #ffffff;
}
</style>
