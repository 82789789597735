<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
  >
    <g
      id="Gestion_administraciones"
      data-name="Gestion administraciones"
      transform="translate(0)"
    >
      <rect
        id="Area"
        width="85"
        height="85"
        transform="translate(0)"
        fill="#fcfcfc"
        opacity="0"
      />
      <g id="Icon" transform="translate(6.072 7.083)">
        <path
          id="Path"
          d="M1.6,23.706,37.615,1.75,74.083,23.706Z"
          transform="translate(-1.187 -1.75)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <path
          id="Path-2"
          data-name="Path"
          d="M7.875,46.492V10.5h21.25V46.492"
          transform="translate(17.928 11.456)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <line
          id="Línea_71"
          data-name="Línea 71"
          x2="72.857"
          transform="translate(0 71.845)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
        <path
          id="Trazado_52801"
          data-name="Trazado 52801"
          d="M0,0H72.857"
          transform="translate(0 59.702)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
        <line
          id="Línea_73"
          data-name="Línea 73"
          y2="36.429"
          transform="translate(64.762 23.273)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
        <line
          id="Línea_74"
          data-name="Línea 74"
          y2="40.476"
          transform="translate(8.095 19.226)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "iconLegales",
};
</script>
<style scoped>
#Gestion_administraciones {
  margin-top: 5px;
}
</style>
