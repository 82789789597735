import Vue from 'vue';
import icons from "./icons"
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2A2A2A",
                secondary: "#0c394e",
                accent: "#b70b67",
                azul: "#4A8AFC",
                azul1: "#4587FD",
                azul2: "#3980FD",
                blancofondo: "#F4F6F7",
                blanco: "#FFFFFF",
                negroappbar: "#2A2A2A"
            }
        }
    },
    icons: {
        values: icons.values,
    },
});
