import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: "LoginLayout",
		view: "Login",
		auth: true, // A cambiar mas adelante
    // user: null,
    user: null,
    offsetVista: 95,
    offsetVistaGlobal: 60,
    offsetAncho: 10,
    offsetWidthP: 530,//el de los proyectos
    titulo: "",
    icono: "",
    // items_migasdepan: [],
    transferirDatos: { crearUsuario: {} },
    crearDatos: null,
    componenteActual: "CompDefault",
    componenteProyecto: "CompDefault",
    // mini: false,
    /*id_usuario;
      nombre_usuario;
      perfil;
      esSuper;
      esAdministrador;
      esGanadero;
      esVeterinario;
      cod_ganaderia;
      cod_socio;
      cod_cliente;
      logueado; */
  },
  getters: {
    isMobile: () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            ) &&
            "ontouchstart" in document.documentElement &&
            navigator.userAgent.match(/Mobi/),
  },
  mutations: {
    SET_LAYOUT(state, newLayout) {
			state.layout = newLayout
		},
    SET_USER(state, payload) {
			state.user = payload
			state.auth = true
    },
    /* UPDATE_MIGAJAS(state, payload) {
      state.items_migasdepan.push(payload);
    }, */
    UPDATE_TRANSFERIR_DATOS(state, { key, value }) {
      console.log("log en transferir datos, key:", key, "value:",value);
      state.transferirDatos[key] = value
      console.log("transferir datos con update", state.transferirDatos[key]);
    },
    UPDATE_CREAR_DATOS(state, payload) {
      state.crearDatos = payload
    },
    UPDATE_COMPONENTE_ACTUAL(state, componente) {
      state.componenteActual = componente;
    },
    UPDATE_COMPONENTE_PROYECTO(state, componente) {
      state.componenteProyecto = componente;
    },
    UPDATE_TITULO(state, componente) {
      state.titulo = componente;
    },
    UPDATE_ICONO(state, componente) {
      state.icono = componente;
    },
    // UPDATE_MINIMIZAR(state, valor) {
    //   state.mini = valor;
    // }
  },
  actions: {
    changeLayout({ commit }, newLayout) {
			commit("SET_LAYOUT", newLayout)
		},
    loginUser({ commit }, payload) {
			commit("SET_USER", payload)
    },
    /* updateMigajas({ commit }, payload) {
      commit("UPDATE_MIGAJAS", payload);
    }, */
    /* async updateTransferirDatos({ commit }, { key, value }) {
        try {
          commit("UPDATE_TRANSFERIR_DATOS", { key, value })
        } catch (error) {
          console.error(error)
        }
      
    }, */
    async updateTransferirDatos({ commit }, { key, value }) {
        commit("UPDATE_TRANSFERIR_DATOS", { key, value })
    },
    updateCrearDatos({ commit }, payload) {
      commit("UPDATE_CREAR_DATOS", payload)
    },
    updateComponenteActual({ commit }, componente) {
      commit("UPDATE_COMPONENTE_ACTUAL", componente)
    },
    updateComponenteProyecto({ commit }, componente) {
      commit("UPDATE_COMPONENTE_PROYECTO", componente)
    },
    updateTitulo({ commit }, componente) {
      commit("UPDATE_TITULO", componente)
    },
    updateIcono({ commit }, componente) {
      commit("UPDATE_ICONO", componente)
    },
    // updateMinimizar({ commit }, valor) {
    //   commit("UPDATE_MINIMIZAR", valor)
    // }
  },
  modules: {
  }
})
