import Axios from "axios"; 

// Definición de la instancia
const HTTP = Axios.create({
	baseURL: process.env.VUE_APP_BASEURL,
	withCredentials: false,
	headers: {
		Accept: "application/json",
		"Content-type": "application/json",
	},
});

// Response interceptors
// HTTP.interceptors.response.use(
//     (resp) => {
//         if (resp.status === 204) {
//             this.$store.dispatch("setInfoData", {
//                 text: "Sin datos disponibles",
//             })
//             this.$store.dispatch("activeInfo")
//             setTimeout(() => this.$store.dispatch("disableInfo"), 2250)
//         }

//         if (!Array.isArray(resp.data) && resp.data.code) {
//             this.$store.dispatch("setSuccessData", {
//                 text: resp.data.message,
//             })
//             this.$store.dispatch("activeSuccess")
//             setTimeout(() => this.$store.dispatch("disableSuccess"), 2250)
//         }

//         this.$store.dispatch("disableProgress")
//         return Promise.resolve(resp)
//     },
//     (err) => {
//         // Modo desarrollo
//         if (process.env.NODE_ENV === "development") {
//             if (err.response.status === 404) {
//                 this.$store.dispatch("setErrorData", {
//                     type: "warning",
//                     cod: err.response.status,
//                     text: `${err.response.config.url} - ${err.response.statusText}`,
//                 })
//             } else if (err.response.status === 500) {
//                 this.$store.dispatch("setErrorData", {
//                     type: "error",
//                     cod: err.response.status,
//                     text: `Endpoint failure: ${err.response.config.url}`,
//                 })
//                 // setTimeout(() => this.$store.dispatch("removeAllErrors"), 2250)
//             } else if (err.response.status === 400) {
//                 this.$store.dispatch("setErrorData", {
//                     type: "warning",
//                     cod: err.response.data.code,
//                     text: err.response.data.message,
//                 })
//                 // setTimeout(() => this.$store.dispatch("removeAllErrors"), 2250)
//             }
//         }

//         // Modo producción
//         if (process.env.NODE_ENV === "production") {
//             // app.ebersy.com
//             if (location.hostname === 'app.ebersy.com') {

//                 if (err.response.status === 400) {
//                     this.$store.dispatch("setErrorData", {
//                         type: "warning",
//                         cod: err.response.data.code,
//                         text: err.response.data.message,
//                     })
//                     // setTimeout(() => this.$store.dispatch("removeAllErrors"), 2250)
//                 }

//                 if (err.response.status === 409) {
//                     this.$store.dispatch("setErrorData", {
//                         type: "warning",
//                         cod: err.response.data.code,
//                         text: err.response.data.message,
//                     })
//                     //setTimeout(() => this.$store.dispatch("removeAllErrors"), 2250)
//                 }

//                 if (err.response.status === 500) {
//                     this.$store.dispatch("setErrorData", {
//                         type: "error",
//                         cod: err.response.status,
//                         text: `Endpoint failure: ${err.response.config.url}`,
//                     })
//                     // setTimeout(() => this.$store.dispatch("removeAllErrors"), 2250)
//                 }
//             }
//         }

//         this.$store.dispatch("disableProgress")
//         return Promise.reject(err)
//     }
// )

export default {
	getJSON(endpoint) {
		return HTTP.get(endpoint.toLowerCase());
	},
	postJSON(endpoint, payload) {
		return HTTP.post(endpoint.toLowerCase(), { ...payload });
	},
};
