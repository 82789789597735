<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="86"
    viewBox="0 0 86 86"
  >
    <g id="Icon" transform="translate(0 0)">
      <rect
        id="Area"
        width="86"
        height="86"
        transform="translate(0 0)"
        fill="#fcfcfc"
        opacity="0"
      />
      <g id="Icon-2" data-name="Icon" transform="translate(10.745 7.152)">
        <path
          id="Path"
          d="M44.429,17.086a3.582,3.582,0,0,0,0,5.018l5.732,5.732a3.582,3.582,0,0,0,5.018,0L68.69,14.325A21.5,21.5,0,0,1,40.237,42.778L15.477,67.538a7.6,7.6,0,0,1-10.75-10.75L29.487,32.028A21.5,21.5,0,0,1,57.94,3.575L44.464,17.052Z"
          transform="translate(-2.499 -1.663)"
          fill="none"
          stroke="#3980fd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "iconTrabajo",
};
</script>

<style scoped>
#Icon {
  margin-top: 5px;
}
</style>
