// import { read, utils } from "xlsx";
export const files = {
  methods: {
    $_processExcelFile(file) {
      return new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = function (e) {
            // var bytes = new Uint8Array(e.target.result);
            // var wb = read(bytes);
            // var wsname = wb.SheetNames[0];
            // var ws = wb.Sheets[wsname];
            // var JSON = utils.sheet_to_json(ws, { header: 1 });

            // resolve(JSON);

            resolve(e.target.result);
          };

          reader.readAsText(file["file"]);
        } catch (err) {
          reject(err);
        }
      });
    },

    $_numeroDecimal(cadena) {
      try {
        let comas = (String(cadena).match(/,/g) || []).length;
        let puntos = (String(cadena).match(/\./g) || []).length;
        switch (puntos) {
          case 0:
            if (comas == 1) {
              cadena = cadena.replace(/,/g, ".");
            } else {
              cadena = cadena.replace(/,/g, "");
            }
            break;
          case 1:
            if (comas == 1) {
              if (cadena.lastIndexOf(".") > cadena.lastIndexOf(",")) {
                cadena = cadena.replace(/,/g, "");
              } else {
                cadena = cadena.replace(/\./g, "");
                cadena = cadena.replace(/,/g, ".");
              }
            } else {
              cadena = cadena.replace(/,/g, "");
            }
            break;
          default:
            cadena = cadena.replace(/\./g, "");
            cadena = cadena.replace(/,/g, ".");
        }
        return cadena;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
