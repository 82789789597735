import { render, staticRenderFns } from "./iconCorderos.vue?vue&type=template&id=ca0b385e&scoped=true&"
import script from "./iconCorderos.vue?vue&type=script&lang=js&"
export * from "./iconCorderos.vue?vue&type=script&lang=js&"
import style0 from "./iconCorderos.vue?vue&type=style&index=0&id=ca0b385e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca0b385e",
  null
  
)

export default component.exports