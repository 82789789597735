<template>
  <v-sheet>
    <!--filtro-->
    <v-row justify="center" align="center" class="mt-2">
      <v-col cols="12">
        <p class="text-center azul1--text mb-4">{{ filtroTitle }}</p>
        <p class="text-center azul1--text mb-4">{{ filtroTitle2 }}</p>
        <v-sheet rounded elevation="4" class="mb-4 ml-3 mr-1" id="filtro">
          <slot name="filtro"></slot>
        </v-sheet>
      </v-col>
    </v-row>

    <!--botones-->
    <div class="text-center">
      <slot name="botones"> </slot>
    </div>

    <!--tabla-->
    <v-sheet class="ml-3 mr-1" style="overflow-x: hidden; overflow-y: scroll">
      <slot name="tabla"></slot>
    </v-sheet>

    <!-- dialogs -->
    <slot name="dialog" id="dialog"></slot>
  </v-sheet>
</template>

<script>
export default {
  name: "VistaModeloProyectos",
  props: {
    filtroTitle: {
      type: String,
      default: "Título filtro...",
    },
    filtroTitle2: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    altoVista: window.innerHeight,
    anchoVista: window.innerWidth,
  }),
  methods: {
    onResize() {
      this.altoVista = window.innerHeight - this.$store.state.offsetVista;
      console.log("Ancho de la vista: ", window.innerWidth);
      this.anchoVista = window.innerWidth - this.$store.state.offsetWidthP;
      console.log("Ancho de la vista con el onResize: ", this.anchoVista);
    },
  },
};
</script>

<style>
#filtro {
  background-color: #f4f6f7;
}
#dialog {
  background-color: #ffffff;
}
</style>
