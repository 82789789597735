<template>
  <v-sheet :height="altoVista" width="100%" id="vistamodelo" class="ml-n3">
    <v-container fluid class="mr-n3">
      <v-row :justify="justify" :align="align">
        <v-col cols="12" class="pa-0">
          <slot name="tabs"></slot>
        </v-col>
        <v-col cols="12" class="pa-0">
          <slot name="default"></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "VistaModelo",
  props: {
    justify: {
      type: String,
      required: false,
      default: "center",
    },
    align: {
      type: String,
      required: false,
      default: "start",
    },
  },
  data: () => ({
    altoVista: window.innerHeight - 50,
  }),
};
</script>

<style scoped>
#vistamodelo {
  background-color: "#f4f6f7";
}
</style>
