import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VistaModelo from "@/components/VistaModelo.vue";
import VistaModeloLienzos from "@/components/VistaModeloLienzos.vue";
import VistaModeloProyectos from "@/components/VistaModeloProyectos.vue";
import HTTP from "@/shared/servicios/http.js";
import { files } from "@/shared/mixins/files.js";

Vue.config.productionTip = false;
Vue.component("VistaModelo", VistaModelo);
Vue.component("VistaModeloLienzos", VistaModeloLienzos);
Vue.component("VistaModeloProyectos", VistaModeloProyectos);
Vue.prototype.$http = HTTP;
Vue.mixin(files);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
