<template>
  <svg
    id="Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="86"
    viewBox="0 0 86 86"
  >
    <rect id="Area" width="86" height="86" fill="#fcfcfc" opacity="0" />
    <g id="Icon-2" data-name="Icon" transform="translate(7.163 7.168)">
      <path
        id="Path"
        d="M70.5,49.159a35.834,35.834,0,1,1-47.334-46.8"
        transform="translate(-1.666 0.613)"
        fill="none"
        stroke="#3980fd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <path
        id="Path-2"
        data-name="Path"
        d="M45.832,37.5A35.833,35.833,0,0,0,10,1.667V37.5Z"
        transform="translate(25.837 -1.667)"
        fill="none"
        stroke="#3980fd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "iconGTE2",
};
</script>
<style scoped>
#Icon {
  margin-top: 5px;
}
</style>
