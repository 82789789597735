<template>
  <div>
    <component :is="layout"></component
    ><!--renderiza los layouts-->
    <alert-item mensaje=""></alert-item>
    <!-- Hay que hacer aquí un componente que sea un alert y desde ahí se rendericen los fallos del back -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    LoginLayout: () => import("./views/Layouts/LoginLayout.vue"),
    AppLayout: () => import("./views/Layouts/AppLayout.vue"),
    AlertItem: () => import("./components/modulos/AlertItem.vue"),
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;700&display=swap");
</style>
