<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.69"
        height="20.69"
        viewBox="0 0 20.69 20.69"
    >
        <g id="iconComunEyeOff" transform="translate(0.345 0.345)">
            <rect id="Area" width="20" height="20" fill="#e4e4e4" opacity="0" />
            <g id="Icon" transform="translate(1.29 2.499)">
                <path
                    id="Path"
                    d="M14.95,14.95A8.4,8.4,0,0,1,10,16.667C4.167,16.667.833,10,.833,10A15.389,15.389,0,0,1,5.05,5.05m3.2-1.517a7.639,7.639,0,0,1,1.75-.2c5.833,0,9.167,6.667,9.167,6.667a15.427,15.427,0,0,1-1.8,2.658m-5.6-.891A2.5,2.5,0,1,1,8.233,8.233"
                    transform="translate(-1.29 -2.499)"
                    fill="none"
                    stroke="#757575"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.667"
                />
                <line
                    id="Line"
                    x2="18.333"
                    y2="18.333"
                    transform="translate(-0.457 -1.666)"
                    fill="none"
                    stroke="#757575"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.667"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "iconComunEyeOff",
}
</script>
