<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g id="iconComunEye" transform="translate(0)">
            <rect id="Area" width="20" height="20" fill="#e4e4e4" opacity="0" />
            <g id="Icon" transform="translate(1.29 2.499)">
                <path
                    id="Path"
                    d="M.833,10S4.167,3.333,10,3.333,19.167,10,19.167,10,15.833,16.667,10,16.667.833,10,.833,10Z"
                    transform="translate(-1.29 -2.499)"
                    fill="none"
                    stroke="#757575"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.667"
                />
                <circle
                    id="Path-2"
                    data-name="Path"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(6.21 5.001)"
                    fill="none"
                    stroke="#757575"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.667"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "iconComunEye",
}
</script>
