<template>
    <svg
        id="iconComunPassword"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        class="mt-1"
    >
        <rect id="Area" width="25" height="25" fill="none" opacity="0" />
        <g id="Icon" transform="translate(1.29 2.499)">
            <rect
                id="Rect"
                width="15"
                height="9.167"
                rx="2"
                transform="translate(1.21 6.668)"
                fill="none"
                stroke="#d4e2e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.667"
            />
            <path
                id="Path"
                d="M5.833,9.167V5.833a4.167,4.167,0,0,1,8.334,0V9.167"
                transform="translate(-1.29 -2.499)"
                fill="none"
                stroke="#d4e2e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.667"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "iconComunPassword",
}
</script>
