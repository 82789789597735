<template>
    <svg
        id="Usuario"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        class="mt-1"
    >
        <rect id="Area" width="25" height="25" fill="#fcfcfc" opacity="0"/>
        <g id="Icon" transform="translate(1.29 2.499)">
            <path
                id="Path"
                d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5"
                transform="translate(-1.29 -2.499)"
                fill="none"
                stroke="#d4e2e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.667"
                 
            />
            <circle
                id="Path-2"
                data-name="Path"
                cx="3.333"
                cy="3.333"
                r="3.333"
                transform="translate(5.377 0.001)"
                fill="none"
                stroke="#d4e2e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.667"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "iconConfiguracionMiAppUsuario",
}
</script>

<style scoped>

#usuario{
    margin-top: 5px;
}

</style>
