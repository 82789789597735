<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="86"
    viewBox="0 0 86 86"
  >
    <g id="Gestion" transform="translate(0.383 0.383)">
      <rect
        id="Area"
        width="86"
        height="86"
        transform="translate(-0.383 -0.383)"
        fill="#fcfcfc"
        opacity="0"
      />
      <g id="Icon" transform="translate(3.551 10.654)">
        <path
          id="Path"
          d="M66.426,6.667V52.834H2.5V6.667"
          transform="translate(4.603 11.092)"
          fill="none"
          stroke="#3980fd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <rect
          id="Rect"
          width="78.132"
          height="17.757"
          fill="none"
          stroke="#3980fd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <line
          id="Line"
          x2="14.206"
          transform="translate(31.963 31.963)"
          fill="none"
          stroke="#3980fd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "iconGestion",
};
</script>

<style scoped>
#Gestion {
  margin-top: 5px;
}
</style>
