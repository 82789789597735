import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // Redireccionamiento
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/inicio",
    name: "InicioView",
    component: () =>
      import(
        /* webpackChunkName: "InicioView" */ "../views/inicio/InicioView.vue"
      ),
    /* meta: {
			migaja: {text: "Inicio", to: "InicioView"},
			// requireAuth: true,
		}, */
  },
  {
    path: "/gestion-pagina",
    name: "GestionView",
    component: () =>
      import(
        /* webpackChunkName: "GestionView" */ "../views/gestion-pagina/GestionView.vue"
      ),
    /* meta: {
			migaja: {text: "Gestion", to: "GestionView"},
			/* requireAuth: true, */
    /*  }, */
  },
  {
    path: "/tus-corderos",
    name: "CorderosView",
    component: () =>
      import(
        /* webpackChunkName: "CorderosView" */ "../views/tus-corderos/CorderosView.vue"
      ),
    /* meta: {
			migaja: {text: "Gestion", to: "GestionView"},
			/* requireAuth: true, */
    /*  }, */
  },
  {
    path: "/aspectos-legales",
    name: "LegalesView",
    component: () =>
      import(
        /* webpackChunkName: "LegalesView" */ "../views/aspectos-legales/LegalesView.vue"
      ),
    /* meta: {
			requireAuth: true,
		}, */
  },
  {
    path: "/gte2",
    name: "GTEView",
    component: () =>
      import(/* webpackChunkName: "GTEView" */ "../views/gte2/GTEView.vue"),
    /* meta: {
			requireAuth: true,
		}, */
  },
  {
    path: "/evohigiene",
    name: "EvohigieneView",
    component: () =>
      import(
        /* webpackChunkName: "EvohigieneView" */ "../views/evohigiene/EvohigieneView.vue"
      ),
    /* meta: {
			requireAuth: true,
		}, */
  },
  {
    path: "/area-trabajo",
    name: "AreaTrabajoView",
    component: () =>
      import(
        /* webpackChunkName: "AreaTrabajoView" */ "../views/area-trabajo/AreaTrabajoView.vue"
      ),
    /* meta: {
			requireAuth: true,
		}, */
  },
  {
    path: "/perfil",
    name: "PerfilView",
    component: () =>
      import(
        /* webpackChunkName: "PerfilView" */ "../views/perfil/PerfilView.vue"
      ),
    /* meta: {
			requireAuth: true,
		}, */
  },
  {
    path: "/herramientas",
    name: "HerramientasView",
    component: () =>
      import(
        /* webpackChunkName: "HerramientasView" */ "../views/herramientas/HerramientasView.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: "active-link",
  linkExactActiveClass: "exact-active-link",
});

export default router;
